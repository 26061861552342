/* Load font faces here */

@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 400;
	unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
	src: local('Roboto'), local('Roboto-Regular'),
		url(https://fonts.gstatic.com/s/roboto/v18/sTdaA6j0Psb920Vjv-mrzH-_kf6ByYO6CLYdB4HQE-Y.woff2)
			format('woff2');
	font-family: Roboto;
}

@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 400;
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
	src: local('Roboto'), local('Roboto-Regular'),
		url(https://fonts.gstatic.com/s/roboto/v18/uYECMKoHcO9x1wdmbyHIm3-_kf6ByYO6CLYdB4HQE-Y.woff2)
			format('woff2');
	font-family: Roboto;
}

@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 400;
	unicode-range: U+0370-03FF;
	src: local('Roboto'), local('Roboto-Regular'),
		url(https://fonts.gstatic.com/s/roboto/v18/tnj4SB6DNbdaQnsM8CFqBX-_kf6ByYO6CLYdB4HQE-Y.woff2)
			format('woff2');
	font-family: Roboto;
}

@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 400;
	unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
	src: local('Roboto'), local('Roboto-Regular'),
		url(https://fonts.gstatic.com/s/roboto/v18/NJ4vxlgWwWbEsv18dAhqnn-_kf6ByYO6CLYdB4HQE-Y.woff2)
			format('woff2');
	font-family: Roboto;
}

@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 400;
	unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF,
		U+2C60-2C7F, U+A720-A7FF;
	src: local('Roboto'), local('Roboto-Regular'),
		url(https://fonts.gstatic.com/s/roboto/v18/Ks_cVxiCiwUWVsFWFA3Bjn-_kf6ByYO6CLYdB4HQE-Y.woff2)
			format('woff2');
	font-family: Roboto;
}

@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 400;
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
		U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
	src: local('Roboto'), local('Roboto-Regular'),
		url(https://fonts.gstatic.com/s/roboto/v18/oMMgfZMQthOryQo9n22dcuvvDin1pK8aKteLpeZ5c0A.woff2)
			format('woff2');
	font-family: Roboto;
}

@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
	src: local('Roboto Medium'), local('Roboto-Medium'),
		url(https://fonts.gstatic.com/s/roboto/v18/ZLqKeelYbATG60EpZBSDy4X0hVgzZQUfRDuZrPvH3D8.woff2)
			format('woff2');
	font-family: Roboto;
}

@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
	src: local('Roboto Medium'), local('Roboto-Medium'),
		url(https://fonts.gstatic.com/s/roboto/v18/oHi30kwQWvpCWqAhzHcCSIX0hVgzZQUfRDuZrPvH3D8.woff2)
			format('woff2');
	font-family: Roboto;
}

@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	unicode-range: U+1F00-1FFF;
	src: local('Roboto Medium'), local('Roboto-Medium'),
		url(https://fonts.gstatic.com/s/roboto/v18/rGvHdJnr2l75qb0YND9NyIX0hVgzZQUfRDuZrPvH3D8.woff2)
			format('woff2');
	font-family: Roboto;
}

@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	unicode-range: U+0370-03FF;
	src: local('Roboto Medium'), local('Roboto-Medium'),
		url(https://fonts.gstatic.com/s/roboto/v18/mx9Uck6uB63VIKFYnEMXrYX0hVgzZQUfRDuZrPvH3D8.woff2)
			format('woff2');
	font-family: Roboto;
}

@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
	src: local('Roboto Medium'), local('Roboto-Medium'),
		url(https://fonts.gstatic.com/s/roboto/v18/mbmhprMH69Zi6eEPBYVFhYX0hVgzZQUfRDuZrPvH3D8.woff2)
			format('woff2');
	font-family: Roboto;
}

@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF,
		U+2C60-2C7F, U+A720-A7FF;
	src: local('Roboto Medium'), local('Roboto-Medium'),
		url(https://fonts.gstatic.com/s/roboto/v18/oOeFwZNlrTefzLYmlVV1UIX0hVgzZQUfRDuZrPvH3D8.woff2)
			format('woff2');
	font-family: Roboto;
}

@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
		U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
	src: local('Roboto Medium'), local('Roboto-Medium'),
		url(https://fonts.gstatic.com/s/roboto/v18/RxZJdnzeo3R5zSexge8UUZBw1xU1rKptJj_0jans920.woff2)
			format('woff2');
	font-family: Roboto;
}

@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 700;
	unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
	src: local('Roboto Bold'), local('Roboto-Bold'),
		url(https://fonts.gstatic.com/s/roboto/v18/77FXFjRbGzN4aCrSFhlh3oX0hVgzZQUfRDuZrPvH3D8.woff2)
			format('woff2');
	font-family: Roboto;
}

@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 700;
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
	src: local('Roboto Bold'), local('Roboto-Bold'),
		url(https://fonts.gstatic.com/s/roboto/v18/isZ-wbCXNKAbnjo6_TwHToX0hVgzZQUfRDuZrPvH3D8.woff2)
			format('woff2');
	font-family: Roboto;
}

@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 700;
	unicode-range: U+1F00-1FFF;
	src: local('Roboto Bold'), local('Roboto-Bold'),
		url(https://fonts.gstatic.com/s/roboto/v18/UX6i4JxQDm3fVTc1CPuwqoX0hVgzZQUfRDuZrPvH3D8.woff2)
			format('woff2');
	font-family: Roboto;
}

@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 700;
	unicode-range: U+0370-03FF;
	src: local('Roboto Bold'), local('Roboto-Bold'),
		url(https://fonts.gstatic.com/s/roboto/v18/jSN2CGVDbcVyCnfJfjSdfIX0hVgzZQUfRDuZrPvH3D8.woff2)
			format('woff2');
	font-family: Roboto;
}

@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 700;
	unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF,
		U+2C60-2C7F, U+A720-A7FF;
	src: local('Roboto Bold'), local('Roboto-Bold'),
		url(https://fonts.gstatic.com/s/roboto/v18/97uahxiqZRoncBaCEI3aW4X0hVgzZQUfRDuZrPvH3D8.woff2)
			format('woff2');
	font-family: Roboto;
}

@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 700;
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
		U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
	src: local('Roboto Bold'), local('Roboto-Bold'),
		url(https://fonts.gstatic.com/s/roboto/v18/d-6IYplOFocCacKzxwXSOJBw1xU1rKptJj_0jans920.woff2)
			format('woff2');
	font-family: Roboto;
}

/** Reset styles*/

*,
::before,
::after {
	background-repeat: no-repeat;
	box-sizing: inherit;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	user-select: none;
	-webkit-touch-callout: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	font-size: 16px;
	font-family: 'roboto';
	text-rendering: optimizelegibility;
	overflow-y: overlay;
	width: 100vw;
}

input::-ms-clear {
	display: none;
}

::before,
::after {
	text-decoration: inherit;
	vertical-align: inherit;
}

::-webkit-scrollbar {
	width: 0.5rem;
	height: 0.5rem;
}

::-webkit-scrollbar-thumb {
	transition: 0.3s ease all;
	border-color: transparent;
	background-color: rgba(168, 183, 245, 0.16);
	z-index: 40;
	border-radius: 3px;
	border: 0.5px solid #a8b7f5;
}

::-webkit-scrollbar-thumb:hover {
	transition: 0.3s ease all;
	background-color: rgba(255, 255, 255, 0.15);
}

html {
	box-sizing: border-box;
	cursor: default;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

article,
aside,
footer,
header,
nav,
section {
	display: block;
}

body {
	margin: 0;
}

h1 {
	font-size: 2em;
	margin: 0.67em 0;
}

figcaption,
figure,
main {
	display: block;
}

figure {
	margin: 1em 40px;
}

hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible;
}

nav ol,
nav ul {
	list-style: none;
}

pre {
	font-family: monospace, monospace;
	font-size: 1em;
}

a {
	background-color: transparent;
	-webkit-text-decoration-skip: objects;
}

a:-webkit-any-link {
	color: inherit;
}

abbr[title] {
	border-bottom: none;
	text-decoration: underline;
	text-decoration: underline dotted;
}

b,
strong {
	font-weight: inherit;
}

b,
strong {
	font-weight: bolder;
}

code,
kbd,
samp {
	font-family: monospace, monospace;
	font-size: 1em;
}

dfn {
	font-style: italic;
}

mark {
	background-color: #ffff00;
	color: #000000;
}

title,
h1,
h2,
h3,
h4,
h5,
label,
span,
p,
pre {
	-moz-user-select: text;
	-webkit-user-select: text;
	-ms-user-select: text;
	user-select: text;
}

small {
	font-size: 80%;
}

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.5em;
}

::-moz-selection {
	background-color: #b3d4fc;
	color: #000000;
	text-shadow: none;
}

::selection {
	background-color: #b3d4fc;
	color: #000000;
	text-shadow: none;
}

audio,
canvas,
iframe,
img,
video {
	vertical-align: middle;
}

audio,
video {
	display: inline-block;
}

audio:not([controls]) {
	display: none;
	height: 0;
}

img {
	border-style: none;
}

svg:not(:root) {
	overflow: hidden;
}

table {
	border-collapse: collapse;
}

button,
input,
optgroup,
select,
textarea {
	margin: 0;
}

button,
input,
select,
textarea {
	color: inherit;
	font-size: inherit;
	line-height: inherit;
}

button,
input {
	overflow: visible;
}

button,
select {
	text-transform: none;
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
	-webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
	border-style: none;
	padding: 0;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
	outline: 1px dotted ButtonText;
}

legend {
	box-sizing: border-box;
	color: inherit;
	display: table;
	max-width: 100%;
	padding: 0;
	white-space: normal;
}

progress {
	display: inline-block;
	vertical-align: baseline;
}

textarea {
	overflow: auto;
	resize: vertical;
}

[type='checkbox'],
[type='radio'] {
	box-sizing: border-box;
	padding: 0;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
	height: auto;
}

[type='search'] {
	-webkit-appearance: textfield;
	outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
	-webkit-appearance: none;
}

::-webkit-file-upload-button {
	-webkit-appearance: button;
	font: inherit;
}

details,
menu {
	display: block;
}

summary {
	display: list-item;
}

canvas {
	display: inline-block;
}

template {
	display: none;
}

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
	-ms-touch-action: manipulation;
	touch-action: manipulation;
}

[hidden] {
	display: none;
}

[aria-busy='true'] {
	cursor: progress;
}

[aria-controls] {
	cursor: pointer;
}

[aria-hidden='false'][hidden]:not(:focus) {
	clip: rect(0, 0, 0, 0);
	display: inherit;
	position: absolute;
}

[aria-disabled] {
	cursor: default;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: background-color 5000s ease-in-out 0s;
	-webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

svg #OTHERS *,
svg text {
	pointer-events: none;
}

a {
	text-decoration: none;
}

a:hover {
	text-decoration: none;
	cursor: pointer;
}

input[type='number'] {
	-moz-appearance: textfield;
}

/* Mozilla specific resets */
*|*:link {
	color: inherit;
}
*:visited {
	color: inherit;
}
